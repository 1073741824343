@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'flowbite';

.scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.bg-green-500 {
    background-color: rgb(77, 206, 163)
}

.overflow-y-scroll {
    scrollbar-width: thin;
    overflow-y: hidden;
}

.overflow-y-scroll::-webkit-scrollbar {
    background-color: transparent;
    height: 4px;
    width: 8px;
}

.overflow-y-scroll::-webkit-scrollbar-track {
    background-color: transparent
}

.overflow-y-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(221, 221, 221);
    border-radius: 8px;
}

.overflow-y-scroll:hover {
    overflow-y: scroll;
}